import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurvaCambioService {
  constructor(private http: HttpClient) { }

  baseUrl = "api/CurvaCambio/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    })
  }


  public cadastrarCambioService(dto) {
    return this.http.post<any>(this.baseUrl + "CadastrarCurvaCambio", JSON.stringify(dto), this.httpOptions);
  }

  public obterListaCurvaCambio(dto) {
    return this.http.post<any>(this.baseUrl + "ObterListaCurvaCambio", JSON.stringify(dto), this.httpOptions);
  }

  public obterCurvaCambioPorId(id) {
    return this.http.get<any>(this.baseUrl + "ObterCurvaCambioPorId?curvaCambioId=" + id, this.httpOptions);
  }

  public editarCurvaCambio(dto) {
    return this.http.post<any>(this.baseUrl + "EditarCurvaCambio", JSON.stringify(dto), this.httpOptions);
  }

  public inativarCurvaCambio(id) {
    return this.http.get<any>(this.baseUrl + "InativarCurvaCambio?curvaCambioId=" + id, this.httpOptions);
  }

  public obterTaxaDI() {
    return this.http.get<any>(this.baseUrl + "ObterTaxaDI", this.httpOptions);
  }

  public obterCboat(idProduto: number) {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'SemOverlay': 'true'
      })
    }
    return this.http.get<any>(this.baseUrl + "ObterCboat?tipoProduto=" + idProduto, this.httpOptions);
  }

  

  public obterCboatCME(url) {
    return this.http.get<any>(url);
  }

  public obterDolarFuturo(dto) {


    return this.http.post<any>(this.baseUrl + "ObterDolarFuturo", JSON.stringify(dto), this.httpOptions);
  }

  public obterDolarFuturoEmLote(dto) {

    //return from(dto)
    //  .pipe(
    //    mergeMap(
    //      (item) => this.http.post(this.baseUrl + "ObterDolarFuturoEmLote", JSON.stringify(dto), this.httpOptions),
    //      1
    //    )
    //  );


    return this.http.post<any>(this.baseUrl + "ObterDolarFuturoEmLote", JSON.stringify(dto), this.httpOptions);
  }

  public obterDolarFuturoPorPeriodo(dto) {
    return this.http.post<any>(this.baseUrl + "ObterDolarFuturoPorPeriodo", JSON.stringify(dto), this.httpOptions);
  }

  public obterQuintoDiaUtil(data, desteMes) {
    return this.http.get<any>(this.baseUrl + "ObterQuintoDiaUtil?data=" + data + "&desteMes=" + desteMes, this.httpOptions);
  }
}
