import { Component } from '@angular/core';
import { CotacaoService } from '../../../services/cotacaoService';
import { Router, NavigationEnd } from '@angular/router';
import { CurvaCambioService } from '../../../services/CurvaCambioService';
import { PremioService } from '../../../services/premioService';
import { PracaService } from '../../../services/pracaService';
import { ToastService } from '../../../services/toastService';
import { PortoService } from '../../../services/portoService';
import { ComponenteItemService } from '../../../services/componenteItemService';
import { PrecificacaoService } from '../../../services/precificacaoService';
import { NgbModal, ModalDismissReasons, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../../datepicker.custom.adapter';
import { ProdutoEnum } from '../../../enums/ProdutoEnum';
import { ComponenteService } from '../../../services/componenteService';
import { AuthorizationService } from '../../../services/authorizationService';
import { Globals } from '../../../globals/globals';

@Component({
  selector: 'app-precificacao',
  templateUrl: '../../base/precificacao.html',
  styleUrls: ['../../base/precificacao.styled.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class Precificacao {

  constructor(private premioService: PremioService,
    private pracaService: PracaService,
    private toastService: ToastService,
    private router: Router,
    private curvaCambioService: CurvaCambioService,
    private portoService: PortoService,
    private componenteItemService: ComponenteItemService,
    private precificacaoService: PrecificacaoService,
    private modalService: NgbModal,
    private componenteService: ComponenteService,
    private authorizationService: AuthorizationService,
    private globals: Globals) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        clearInterval(this.setIntervalId);
      }
    });

  }

  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  ehAdministrador = false;
  resposta: any = {};
  dto: any = {
    dataInicio: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
    dataFim: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
    spread: 0.015,
    cambioMerc: {},
    cambioRodo: {},
    cambioFerro: {},
    cambioPremio: {},
    taxaCupom: 2.6,
    premio: {},
    rodo: {},
    pracaFerro: {},
    ferro: {},
    pracaRodo: {},
    cBotNumber: 0,
    cBotLastTrade: 0,
    cBotCode: "",
    idMoeda: null
  };
  cBoat: any = [];
  premios: any = [];
  filtro: any = {
    tamanhoPagina: 100,
    numeroPagina: 1,
    tipoFrete: ""
  };
  filtroPrec: any = {
    tamanhoPagina: 100,
    numeroPagina: 1,
  };
  filtroFerrovia: any = {
    tamanhoPagina: 100,
    pagina: 1,
    tipoFrete: "2"
  };
  filtroRodovia: any = {
    tamanhoPagina: 100,
    pagina: 1,
    tipoFrete: "1"

  };
  listaPraca: any[] = [];
  listaPortos: any[] = [];
  listaRodo: any[] = [];
  listaFerro: any[] = [];
  dolarFuturo: any = {};
  listaComponente: any = [];
  listaItemComponente: any[] = [];
  listaPrecificacao: any[] = [];
  soma: number = 0;
  dataRodo: string;
  dataFerro: string;
  dataMerc: string;
  dataMin: string;
  dataPremio: string;
  dataMax: string;
  setIntervalId: any;
  precificacaoSelecionada: {};
  closeResult: string;
  listaComponentesRetencao: any[] = [];
  milho: boolean = false;

  readonly fatorSoja: number = 0.367454;

  ngOnInit() {
    this.obterPortosAtivosEstado();
    this.obterCbot();
    this.ehAdministrador = this.authorizationService.ehAdministrador();
    this.setIntervalId = setInterval(() => {
      this.obterCbot();
    }, 7000);
    this.obterListaComponente();
    var novaData = new Date();

    this.dataMin = `${novaData.getFullYear().toString()}-${("0" + (novaData.getMonth() + 1)).slice(-2).toString()}-${("0" + novaData.getDate()).slice(-2).toString()}`;
    this.dataMax = `${(novaData.getFullYear() + 5).toString()}-${("0" + (novaData.getMonth() + 1)).slice(-2).toString()}-${("0" + novaData.getDate()).slice(-2).toString()}`;
    this.globals.customVariable.subscribe({
      next: newValue => {
        this.resposta = newValue;
      }
    });
  }


  obterPortosAtivosEstado() {
    this.portoService.obterTodosAtivosPrecificacao().subscribe(data => {
      if (data.result) {
        this.listaPortos = data.result;
      }
    });
  }


  podeEditarPrecicificacao() {
    var podeEditar = this.dto.premio != null ? Object.keys(this.dto.premio).length > 0 : true;
    return !(podeEditar);
  }

  carregarListas() {
    this.limparListaFrete();
    this.obterPremios();
    this.obterListaPraca();
    this.obterListaComponente();
  }

  obterPremios() {
    if (this.dto.portoInicial == null || this.dto.portoInicial.portoId == null) return;

    this.premioService.obterPremiosPrecificacao(ProdutoEnum.SOJA, this.dto.portoInicial.portoId).subscribe(response => {
      if (response.codigo === 200)
        this.premios = response.result;
    })
  }

  obterListaPraca() {
    this.filtro.idPorto = this.dto.portoInicial.portoId;
    this.pracaService.obterListaPracaPrecificacao(this.filtro).subscribe(response => {
      if (response.codigo === 200) {
        this.listaPraca = response.result.retorno;
        this.filtro.total = response.result.qtdTotal;
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  limparListaFrete() {
    this.dto.pracaRodo = {};
    this.dto.pracaFerro = {};
    this.limparListaRodo();
    this.limparListaFerro();

  }

  limparListaFerro() {
    this.listaFerro = [];
    this.dto.ferro = {};
  }

  limparListaRodo() {
    this.listaRodo = [];
    this.dto.rodo = {};
  }

  obterFreteFerro() {
    this.filtroFerrovia.pracaId = this.dto.pracaFerro.pracaId;
    this.filtroFerrovia.idPorto = this.dto.portoInicial.portoId.toString();
    this.filtroFerrovia.idPremio = this.dto.premio.premioId;
    this.precificacaoService.obterFretePorUsuario(this.filtroFerrovia).subscribe(response => {
      if (response.codigo === 200) {
        this.listaFerro = response.result.retorno;
        if (Object.keys(this.dto.premio).length > 0) {
          this.dto.ferro = this.listaFerro.find(ferro => {
            return ferro.dataFreteString === this.dto.premio.dataPremio;
          })
          if (this.dto.ferro == undefined)
            this.dto.ferro = {};
          else {
            this.trocarDataFerro(this.dto.ferro.dataFreteString);
          }
        }
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  obterFreteRodo() {
    this.filtroRodovia.pracaId = this.dto.pracaRodo.pracaId;
    this.filtroRodovia.idPorto = this.dto.portoInicial.portoId.toString();
    this.filtroRodovia.idPremio = this.dto.premio.premioId;
    this.precificacaoService.obterFretePorUsuario(this.filtroRodovia).subscribe(response => {
      if (response.codigo === 200) {
        this.listaRodo = response.result.retorno;
        if (Object.keys(this.dto.premio).length > 0) {
          this.dto.rodo = this.listaRodo.find(rodo => {
            return rodo.dataFreteString === this.dto.premio.dataPremio;
          })
          if (this.dto.rodo == undefined)
            this.dto.rodo = {};
          else {
            this.trocarDataRodo(this.dto.rodo.dataFreteString);
          }
        }
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  obterQuintoDiaUtil(data, tipo, desteMes) {
    this.curvaCambioService.obterQuintoDiaUtil(data, desteMes).subscribe(response => {
      if (response.codigo == 200) {
        var dataResult = response.result;
        this.obterDolarFuturo(dataResult, tipo);
        if (tipo === 1)
          this.dataMerc = dataResult;

        if (tipo === 2)
          this.dataRodo = this.formatarDataParaPt(dataResult);

        if (tipo === 3)
          this.dataFerro = this.formatarDataParaPt(dataResult);

        if (tipo === 4)
          this.dataPremio = this.formatarDataParaPt(dataResult);

        if (this.dto.componente != null && this.dto.componente.componenteId != null)
          this.obterComponenteItem();
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    });
  }

  obterDolarFuturo(dataFim, hedge) {
    if (dataFim == null) return;

    if (!/[0-9]{2}[-\/][0-9]{2}[-\/][0-9]{4}/.test(dataFim) && !/[0-9]{4}[-][0-9]{2}[-][0-9]{2}/.test(dataFim)) {
      return;
    }

    if (this.dto.taxaCupom == "" || this.dto.taxaCupom == undefined || this.dto.spread == "" || this.dto.spread == undefined) {
      this.toastService.show("Taxa cupom ou spread não informado", { classname: 'bg-danger text-light', delay: 5000 })
      return;
    }

    let dataFimFormatada: string = dataFim;
    if (/[0-9]{2}[-\/][0-9]{2}[-\/][0-9]{4}/.test(dataFim)) {
      dataFimFormatada = dataFim.replace(/([0-9]{2})[-\/]([0-9]{2})[-\/]([0-9]{4})/, "$3-$2-$1");
    }

    var dtoDolar = {
      dolarCompra: this.resposta.cotacaoCompra,
      dolarVenda: this.resposta.cotacaoVenda,
      dataInicio: new Date(),
      dataFim: dataFimFormatada
    }

    this.curvaCambioService.obterDolarFuturo(dtoDolar).subscribe(response => {
      if (response.codigo === 200) {
        if (hedge === 1)
          this.dto.cambioMerc = response.result;
        if (hedge === 2)
          this.dto.cambioRodo = response.result;
        if (hedge === 3)
          this.dto.cambioFerro = response.result;
        //Câmbio prêmio
        if (hedge === 4)
          this.dto.cambioPremio = response.result;

      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
    })
  }

  formatarDataParaPt(strData: string) {
    var format = strData.split('-');
    return format[2] + '/' + format[1] + '/' + format[0];
  }

  formatarDataPtParaEn(strDataPt: string) {
    var format = strDataPt.split('/');
    return format[2] + '-' + format[1] + '-' + format[0];
  }

  obterListaComponente() {
    this.dto.componente = null;
    this.listaItemComponente = [];
    var portoId = this.dto.portoInicial == null || this.dto.portoInicial.portoId == null ? "#" : this.dto.portoInicial.portoId;
    this.componenteService.obterComponentesPorUsuarioLogadoEVinculos(ProdutoEnum.SOJA, portoId).subscribe(response => {
      if (response.codigo = 200) {
        this.listaComponente = response.result;
      }
    });
  }

  obterComponenteItem() {

    this.listaComponentesRetencao = [];

    if (this.dto.ferro == null || this.dto.cambioFerro.dolarCompra == "" || this.dto.cambioFerro.dolarCompra == undefined || this.dto.cambioFerro.dolarVenda == "" || this.dto.cambioFerro.dolarVenda == undefined) {
      this.toastService.show("Selecione o frete erroviario.", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }

    var self = this;


    this.componenteItemService.obterComponentePor(this.dto.componente.componenteId, this.dto.cambioFerro.dolarCompra, ProdutoEnum.SOJA, this.dto.cambioFerro.dolarVenda, this.dto.premio.dataPremioPt).subscribe(response => {

      this.soma = 0;

      if (response.codigo === 200) {
        this.listaItemComponente = response.result;

        var retencoesCadastradas: any[] = []

        this.listaItemComponente.forEach(function (val) {
          if (val.calculo == 0 || val.calculo == null || val.calculo == undefined) {
            self.soma += val.custo;
          } else {
            val.ehCalculo = true;
            retencoesCadastradas.push(val);
          }
        }, 0);

        this.calcularRetencao(retencoesCadastradas);
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
        this.listaItemComponente = [];
      }
    })

  }

  trocarDataFerro(dataFerro) {
    var data = dataFerro.split('/');
    var novaData = new Date(parseInt(data[1]), parseInt(data[0]), 5);
    this.dataFerro = `${novaData.getFullYear().toString()}-${("0" + (novaData.getMonth() + 1)).slice(-2).toString()}-${("0" + novaData.getDate()).slice(-2).toString()}`;
    this.obterDolarFuturo(novaData, 3);
  }

  calcularRetencao(retencoesPorto) {

    var custos = 0;
    var self = this;

    retencoesPorto.forEach(function (retencao) {
      custos += self.calcularRetencaoPorComponente(retencao.calculo, retencao.custo);
    });

    this.soma += custos;
  }

  calculoComponenteCompra(componente) {

    if (componente.ehCalculo) {
      componente.compra = this.calcularRetencaoPorComponente(componente.calculo, componente.custo);
    }
    else {
      componente.compra = componente.custo;
    }

    return componente.compra;
  }

  calculoComponenteVenda(componente) {
    if (componente.ehCalculo) {
      componente.venda = this.calcularRetencaoPorComponente(componente.calculo, componente.custo);
    }
    else {
      componente.venda = componente.custoEmDolarVenda;
    }
    return componente.venda;
  }

  calcularRetencaoPorComponente(tipoCalculo, custo) {

    var self = this;

    //FOB ORIGEM
    if (tipoCalculo == 1) {

      var baseRetencao = self.obterBaseRetencao();
      var freteSelecionado = self.obterFreteRodoFerroSelecionado();

      var fobOrigem = (baseRetencao - freteSelecionado - self.soma);
      var fob = +(fobOrigem * custo / 100).toFixed(4);

      return fob;

    }
    //TRANSBORDO
    else if (tipoCalculo == 2) {

      var baseRetencao = self.obterBaseRetencao();
      var ferro = self.obterFreteFerroSelecionado();

      var custoQuebraTransbordo = (baseRetencao - ferro - self.soma);
      var transbordo = custoQuebraTransbordo * custo / 100;
      return +transbordo.toFixed(4);
    }
    //POOL
    else if (tipoCalculo == 3) {
      var custoRetencaoPool = (self.obterBaseRetencao() - self.soma) * custo / 100;
      return +custoRetencaoPool.toFixed(4);
    }

    return 0;
  }

  public obterBaseRetencao() {
    if (!isNaN(this.dto.cBotNumber) && this.dto.cBotNumber != undefined && this.dto.premio.valor != undefined) {

      var e = +((this.dto.cBotNumber + this.dto.premio.valor) * this.fatorSoja).toFixed(4)
      return e;

    }
    return 0;
  }

  obterFreteRodoFerroSelecionado() {
    if (this.dto.rodo.valor != undefined && this.dto.cambioRodo.dolarCompra != undefined && this.dto.ferro.valor != undefined && this.dto.cambioFerro.dolarCompra != undefined) {
      var e = +((this.dto.rodo.valor / this.dto.cambioRodo.dolarCompra) + (this.dto.ferro.valor / this.dto.cambioFerro.dolarCompra)).toFixed(4);
      return e;
    }

    return 0;

  }

  obterFreteFerroSelecionado() {
    if (this.dto.ferro.valor != undefined && this.dto.cambioFerro.dolarCompra != undefined) {
      var e = +(this.dto.ferro.valor / this.dto.cambioFerro.dolarCompra).toFixed(4);
      return e;
    }

    return 0;

  }

  trocarDataRodo(dataRodo) {
    var data = dataRodo.split('/');
    var novaData = new Date(parseInt(data[1]), parseInt(data[0]), 5);
    this.dataRodo = `${novaData.getFullYear().toString()}-${("0" + (novaData.getMonth() + 1)).slice(-2).toString()}-${("0" + novaData.getDate()).slice(-2).toString()}`;
    this.obterDolarFuturo(novaData, 2);
  }

  bidDolarCompra() {
    if (this.dto.cBotNumber != undefined && this.dto.premio.valor != undefined && this.dto.rodo.valor != undefined && this.dto.cambioRodo.dolarCompra != undefined && this.soma != undefined && this.dto.portoInicial != undefined)
      return (((this.dto.cBotNumber + this.dto.premio.valor) * this.fatorSoja) - ((this.dto.rodo.valor / this.dto.cambioRodo.dolarCompra) + (this.dto.ferro.valor / this.dto.cambioFerro.dolarCompra) + this.soma)) * 0.06

    return 0;
  }

  bidReaisCompra() {
    if (this.dto.cambioMerc.dolarCompra != undefined && this.dto.cambioMerc.dolarCompra > 0)
      return this.bidDolarCompra() * this.dto.cambioMerc.dolarCompra;

    return 0;
  }

  bidDolarVenda() {
    if (this.dto.cBotNumber != undefined && this.dto.premio.valor != undefined && this.dto.rodo.valor != undefined && this.dto.cambioRodo.dolarVenda != undefined && this.soma != undefined && this.dto.portoInicial != undefined)
      return (((this.dto.cBotNumber + this.dto.premio.valor) * this.fatorSoja) - ((this.dto.rodo.valor / this.dto.cambioRodo.dolarVenda) + (this.dto.ferro.valor / this.dto.cambioFerro.dolarVenda) + this.soma)) * 0.06

    return 0;
  }

  bidReaisVenda() {
    if (this.dto.cambioMerc.dolarVenda != undefined && this.dto.cambioMerc.dolarVenda > 0)
      return this.bidDolarVenda() * this.dto.cambioMerc.dolarVenda;

    return 0;
  }

  substituir(code) {
    var bot = this.cBoat.quotes.find(quote => quote.quoteCode == code);

    if (bot == null) {
      this.dto.cBotNumber = null;
      this.dto.cBotLastTrade = null;
      this.dto.cBotCode = null;
      return;
    }
    this.dto.cBotNumber = parseFloat(bot.last.replace(/'/g, '.'));
    this.dto.cBotLastTrade = bot.lastTradeDate;
    this.dto.cBotCode = code;
  }

  ofertaCompraDolarParaReais() {
    if (this.dto.cambioMerc.dolarCompra != undefined && this.dto.cambioMerc.dolarCompra > 0 && this.dto.ofertaCompra != undefined && this.dto.ofertaCompra > 0)
      this.dto.ofertaCompraRs = +(this.dto.ofertaCompra * this.dto.cambioMerc.dolarCompra).toFixed(2);

    if (this.dto.ofertaCompra == null || this.dto.ofertaCompra == 0) {
      this.dto.ofertaCompraRs = 0;
    }

  }

  ofertaCompraReaisParaDolar() {
    if (this.dto.cambioMerc.dolarCompra != undefined && this.dto.cambioMerc.dolarCompra > 0 && this.dto.ofertaCompraRs != undefined && this.dto.ofertaCompraRs > 0)
      this.dto.ofertaCompra = +(this.dto.ofertaCompraRs / this.dto.cambioMerc.dolarCompra).toFixed(2);

    if (this.dto.ofertaCompraRs == null || this.dto.ofertaCompraRs == 0) {
      this.dto.ofertaCompra = 0;
    }

  }

  ofertaVendaReaisParaDolar() {
    if (this.dto.cambioMerc.dolarVenda != undefined && this.dto.cambioMerc.dolarVenda > 0 && this.dto.ofertaVendaRs != undefined && this.dto.ofertaVendaRs > 0)
      this.dto.ofertaVenda = +(this.dto.ofertaVendaRs / this.dto.cambioMerc.dolarVenda).toFixed(2);

    if (this.dto.ofertaVendaRs == null || this.dto.ofertaVendaRs == 0) {
      this.dto.ofertaVenda = 0;
    }

  }

  ofertaVendaDolarParaReais() {
    if (this.dto.cambioMerc.dolarVenda != undefined && this.dto.cambioMerc.dolarVenda > 0 && this.dto.ofertaVenda != undefined && this.dto.ofertaVenda > 0)
      this.dto.ofertaVendaRs = +(this.dto.ofertaVenda * this.dto.cambioMerc.dolarVenda).toFixed(2);

    if (this.dto.ofertaVenda == null || this.dto.ofertaVenda == 0) {
      this.dto.ofertaVendaRs = 0;
    }

  }

  baseCompra() {
    if (this.dto.rodo.valor != undefined && this.dto.cambioRodo.dolarCompra != undefined && this.soma != undefined && this.soma > 0)
      return (this.dto.rodo.valor / this.dto.cambioRodo.dolarCompra) + (this.dto.ferro.valor / this.dto.cambioFerro.dolarCompra) + this.soma;

    return 0;
  }

  baseVenda() {
    if (this.dto.rodo.valor != undefined && this.dto.cambioRodo.dolarVenda != undefined && this.soma != undefined && this.soma > 0)
      return (this.dto.rodo.valor / this.dto.cambioRodo.dolarVenda) + (this.dto.ferro.valor / this.dto.cambioFerro.dolarVenda) + this.soma;

    return 0;
  }

  ofertaCompraCbot() {
    if (this.dto.ofertaCompra != undefined && this.dto.premio.valor != undefined && this.baseCompra() > 0)
      return (((this.dto.ofertaCompra / 0.06) + this.baseCompra()) / this.fatorSoja) - this.dto.premio.valor;

    return 0;
  }

  ofertaVendaCbot() {
    if (this.dto.ofertaVenda != undefined && this.dto.premio.valor != undefined && this.baseVenda() > 0)
      return (((this.dto.ofertaVenda / 0.06) + this.baseVenda()) / this.fatorSoja) - this.dto.premio.valor;

    return 0;
  }

  ofertaCompraPremio() {
    if (this.dto.ofertaCompra != undefined && this.dto.cBotNumber != undefined && this.dto.cBotNumber > 0 && this.baseCompra() > 0)
      return (((this.dto.ofertaCompra / 0.06) + this.baseCompra()) / this.fatorSoja) - this.dto.cBotNumber;

    return 0;
  }

  ofertaVendaPremio() {
    if (this.dto.ofertaVenda != undefined && this.dto.cBotNumber != undefined && this.dto.cBotNumber > 0 && this.baseVenda() > 0)
      return (((this.dto.ofertaVenda / 0.06) + this.baseVenda()) / this.fatorSoja) - this.dto.cBotNumber;

    return 0;
  }

  cadastrar(tipoNegocio) {
    if (this.dto.cBot == undefined) {
      this.toastService.show("Cbot não selecionado!", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }

    if (isNaN(this.dto.cBotNumber) || this.dto.cBotNumber == undefined || this.dto.cBotNumber == null) {
      this.toastService.show("Valor do Cbot está vazia", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }

    if (this.dto.premio == undefined || JSON.stringify(this.dto.premio) == "{}") {
      this.toastService.show("Premio não selecionado!", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }
    if (this.dto.rodo == undefined || JSON.stringify(this.dto.rodo) == "{}") {
      this.toastService.show("Frete rodoviário não selecionado!", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }
    if (this.dataMerc == undefined || this.dataMerc == "") {
      this.toastService.show("Data merc não selecionado!", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }
    if (this.dataRodo == undefined || this.dataRodo == "") {
      this.toastService.show("Data rodo não selecionado!", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }
    if (this.dataFerro == undefined || this.dataFerro == "") {
      this.toastService.show("Data ferro não selecionado!", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }
    if (this.dto.portoInicial == undefined || JSON.stringify(this.dto.portoInicial) == "{}") {
      this.toastService.show("Porto não selecionado!", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }
    if (this.dto.componente == null || this.dto.componente == undefined || JSON.stringify(this.dto.portoInicial) == "{}") {
      this.toastService.show("Componente não selecionado!", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }
    if (this.dto.nome == undefined || this.dto.nome == "") {
      this.toastService.show("Nome inválido!", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }

    if (this.listaItemComponente.length == 0) {
      this.toastService.show("Componente não possui itens", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }

    if (this.dto.idMoeda == null || this.dto.idMoeda == undefined) {
      this.toastService.show("Moeda não selecionada", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }


    var dto = {
      nome: this.dto.nome,
      Cbot: this.dto.cBot,
      CbotLastTrade: this.dto.cBotLastTrade,
      CbotValor: this.dto.cBotNumber,
      premioId: this.dto.premio.premioId,
      portoId: this.dto.portoInicial.portoId,
      componenteId: this.dto.componente.componenteId,
      freteId: this.dto.rodo.freteId,
      dataMerc: this.formatarDataPtParaEn(this.dataMerc),
      dataRodo: this.formatarDataPtParaEn(this.dataRodo),
      dataFerro: this.formatarDataPtParaEn(this.dataFerro),
      valor: 0,
      valorRs: 0,
      tipoNegocio: 0,
      freteFerroId: this.dto.ferro.freteId,

      valorFreteRodo: this.dto.rodo.valor,
      valorFreteFerro: this.dto.ferro.valor,

      hedgeRodoCompra: this.dto.cambioRodo.dolarCompra,
      hedgeRodoVenda: this.dto.cambioRodo.dolarVenda,

      hedgeFerroCompra: this.dto.cambioFerro.dolarCompra,
      hedgeFerroVenda: this.dto.cambioFerro.dolarVenda,

      hedgeMercadoCompra: this.dto.cambioMerc.dolarCompra,
      hedgeMercadoVenda: this.dto.cambioMerc.dolarVenda,

      hedgePremioCompra: this.dto.cambioPremio.dolarCompra,

      idMoeda: this.dto.idMoeda
    };


    if (tipoNegocio === 1) {
      if (this.dto.ofertaCompra == undefined || this.dto.ofertaCompra <= 0) {
        this.toastService.show("Valor de oferta de compra não informado!", { classname: 'bg-danger text-light', delay: 5000 });
        return;
      }
      dto.valor = this.dto.ofertaCompra;
      dto.valorRs = this.dto.ofertaCompraRs;

    }
    if (tipoNegocio === 2) {
      if (this.dto.ofertaVenda == undefined || this.dto.ofertaVenda <= 0) {
        this.toastService.show("Valor de oferta de venda não informado!", { classname: 'bg-danger text-light', delay: 5000 });
        return;
      }
      dto.valor = this.dto.ofertaVenda;
      dto.valorRs = this.dto.ofertaVendaRs;
    }
    dto.tipoNegocio = tipoNegocio;

    this.precificacaoService.cadastrarPrecificacao(dto).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Precificação cadastrada com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.dto.nome = undefined;
        this.dto.cBot = undefined;
        this.dto.cBotLastTrade = undefined;
        this.dto.premio = {};
        this.dto.portoInicial = {};
        this.dto.rodo = {};
        this.dto.ferro = {};
        this.dataMerc = undefined;
        this.dataRodo = undefined;
        this.dataFerro = undefined;
        this.dto.pracaRodo = {};
        this.dto.pracaFerro = {};
        this.dto.ofertaCompra = undefined;
        this.dto.ofertaVenda = undefined;
        this.dto.ofertaCompraRs = undefined;
        this.dto.ofertaVendaRs = undefined;
        this.listaComponente = [];
        this.dto.cambioMerc = {};
        this.dto.cambioRodo = {};
        this.dto.cambioFerro = {};
        this.dto.cBotNumber = 0;
        this.soma = 0;
       
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })

    });
  }

  obterListaPrecificacao(pagina) {
    this.filtroPrec.pagina = pagina;
    this.filtroPrec.spread = 0.015;
    this.filtroPrec.taxaCupom = 2.6;
    this.filtroPrec.dolarCompra = this.resposta.cotacaoCompra;
    this.filtroPrec.dolarVenda = this.resposta.cotacaoVenda;
    this.precificacaoService.obterListaPrecificacao(this.filtroPrec).subscribe(response => {
      if (response.codigo === 200) {
        this.listaPrecificacao = response.result.result.retorno;
        this.filtroPrec.total = response.result.result.qtdTotal
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  calcularBid(precificacao) {
    var cbotValor = this.cBoat.quotes.find(cbot => {
      return cbot.quoteCode == precificacao.cbot;
    });
    var converte = parseFloat(cbotValor.last.replace(/'/g, '.'));
    return (((converte + precificacao.premioValor)
      * this.fatorSoja)
      - (precificacao.valorFrete + precificacao.total)) * 0.06;
  }

  selecionarPrecificacao(precificacao) {
    this.precificacaoSelecionada = precificacao;
  }

  status(precificacao, status) {

    var dto = {
      status: status,
      cbotCode: precificacao.cbot,
      valorCbot: parseFloat(this.cBoat.quotes.find(cbot => {
        return cbot.quoteCode == precificacao.cbot;
      }).last.replace(/'/g, '.')),
      valorDolar: precificacao.tipoNegocioString == "Compra" ? this.resposta.cotacaoCompra : this.resposta.cotacaoVenda,
      valorDolarFuturo: precificacao.hedge,
      valorBid: this.calcularBid(precificacao),
      precificacaoId: precificacao.precificacaoId,
      dataCotacao: this.resposta.dataHoraCotacao.split(' ')[0]
    }
    this.precificacaoService.mudarStatus(dto).subscribe(response => {
      if (response.codigo == 200) {
        this.toastService.show('Oferta concluída com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.listaPrecificacao = this.listaPrecificacao.filter(precificacao => {
          return precificacao.precificacaoId != dto.precificacaoId;
        });
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  countWarning: number = 0;
  private obterCbot() {

    this.curvaCambioService.obterCboat(ProdutoEnum.SOJA).subscribe(response => {
      if (response.mensagem == "CME") {

        if (response.url == null) {
          this.toastService.show("Serviço indiponível no momento");
          return;
        }

        var timer = new Date().getTime();
        var url = response.url;
        var concat = url + timer;
        this.curvaCambioService.obterCboatCME(concat).subscribe(cbot => this.cBoat = cbot);
      }
      else
        this.cBoat = JSON.parse(response.result);

      let count = this.cBoat.quotes != null ? this.cBoat.quotes.filter(a => a.last.includes('-')).length : 0;
      if (count > 0 && this.countWarning == 0) {
        this.toastService.show("Existem " + count + " CBOT sem último valor", { classname: 'bg-warning', delay: 5000 })
        this.countWarning++;
      }
    });
  }


}
